import React from 'react';
import { format } from 'date-fns'
import { Avatar, Button, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Grid2 from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { green, deepOrange, grey } from '@mui/material/colors';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SvgIcon from "@mui/material/SvgIcon";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as Logo } from "./champsLogoNotification.svg";

import './App.css';

const champsTheme = createTheme({
	palette: {
		primary: {
			main: "#64DCBE",
			contrastText: "#FFFFFF#",
		},
		secondary: {
			main: "#8CE6DE",
		},
		mode: "dark",
	},
	typography: {
		button: {
			fontWeight: "bold",
		},
	},
});

class ChampsTabs extends React.Component {
	state = {
		selectedTab: undefined
	}

	handleTabClick = (event, value) => {
		this.setState({selectedTab: value});
	}

	render() {
		return (
			<Tabs textColor="secondary" indicatorColor="secondary" variant="scrollable" value={this.state.selectedTab} onChange={this.handleTabClick}>
			<Tab component={Link} to="/football" label="Football"/>
			<Tab component={Link} to="/handball" label="Handball"/>
			<Tab component={Link} to="/basketball" label="Basketball"/>
			<Tab component={Link} to="/volleyball" label="Volleyball"/>
			<Tab component={Link} to="/rugby" label="Rugby"/>
			<Tab component={Link} to="/judo" label="Judo"/>
			<Tab component={Link} to="/tennisdetable" label="Tennis de table"/>
			<Tab component={Link} to="/tennis" label="Tennis"/>
			</Tabs>
		);
	}
}


export default function App() {
	return (
		<ThemeProvider theme={champsTheme}>
		<CssBaseline />
		<BrowserRouter>
		<AppBar>
		<Toolbar>
		<SvgIcon viewBox="0 0 30 30"><Logo /></SvgIcon>
		<ChampsTabs />
		</Toolbar>
		</AppBar>
		<Routes>
			<Route path="/football" element={<FootballApp />} />
			<Route path="/handball" element={<HandballApp />} />
			<Route path="/basketball" element={<BasketballApp />} />
			<Route path="/volleyball" element={<VolleyballApp />} />
			<Route path="/rugby" element={<RugbyApp />} />
			<Route path="/judo" element={<JudoApp />} />
			<Route path="/tennis" element={<TennisApp />} />
			<Route path="/tennisdetable" element={<TennisDeTableApp />} />
		</Routes>
		</BrowserRouter>
		</ThemeProvider>
	);
}

function FootballApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOFOOTBALL"
	  	sport="Football"
	  	nbStartPlayers={11}
	  	nbSubstPlayers={5}
	  	periods={["1ère mi-temps", "2ème mi-temps", "1ère prolongation", "2ème prolongation"]}
	  	actions={["but", "tir", "cadre", "corner", "faute", "carton_jaune", "carton_rouge"]}
		alpha={true}
	  />
    </div>
  );
}

function HandballApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOHANDBALL"
	  	sport="Handball"
	  	nbStartPlayers={7}
	  	nbSubstPlayers={5}
	  	periods={["1ère mi-temps", "2ème mi-temps", "1ère prolongation", "2ème prolongation"]}
	  	actions={["but", "but_7m", "tir", "arret", "avertissement", "2mn"]}
		alpha={false}
	  />
    </div>
  );
}

function BasketballApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOBASKETBALL"
	  	sport="Basketball"
	  	nbStartPlayers={5}
	  	nbSubstPlayers={5}
	  	periods={["1er quart-temps", "2ème quart-temps", "3ème quart-temps", "4ème quart-temps"]}
		actions={["2point", "3point", "lancer_franc", "rebond_offensif", "rebond_defensif", "interception", "balle_perdue", "faute_individuelle", "faute_equipe", "faute_technique"]}
		alpha={true}
	  />
    </div>
  );
}

function VolleyballApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOVOLLEYBALL"
	  	sport="Volleyball"
	  	nbStartPlayers={6}
	  	nbSubstPlayers={6}
	  	periods={["1er set", "2ème set", "3ème set", "4ème set", "5ème set"]}
	  	actions={["ace", "attaque", "contre", "faute_adverse"]}
		alpha={false}
	  />
    </div>
  );
}

function RugbyApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMORUGBY"
	  	sport="Rugby"
	  	nbStartPlayers={15}
	  	nbSubstPlayers={7}
	  	periods={["1ère mi-temps", "2ème mi-temps", "1ère prolongation", "2ème prolongation"]}
	  	actions={["essai", "transformation", "penalite", "drop"]}
		alpha={false}
	  />
    </div>
  );
}

function JudoApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOJUDO"
	  	sport="Judo"
	  	nbStartPlayers={1}
	  	nbSubstPlayers={0}
	  	periods={["Temps réglementaire", "Golden score"]}
	  	actions={["ippon", "waza_ari", "kinza", "shido"]}
		alpha={false}
	  />
    </div>
  );
}

function TennisDeTableApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOTENNISDETABLE"
		sport="Tennis de Table"
		nbStartPlayers={1}
		nbSubstPlayers={0}
		periods={["1er set", "2ème set", "3ème set", "4ème set", "5ème set"]}
		actions={["coup_gagnant", "faute", "ace", "faute_de_service"]}
		alpha={true}
	  />
    </div>
  );
}

function TennisApp() {
  return (
    <div className="App">
	  <TeamScoreBoard
		codeRencontre="DEMOTENNIS"
		sport="Tennis"
		nbStartPlayers={1}
		nbSubstPlayers={0}
		periods={["1er set", "2ème set", "3ème set", "4ème set", "5ème set"]}
		actions={["coup_gagnant", "faute", "ace", "double_faute"]}
		alpha={false}
	  />
    </div>
  );
}

class TeamScoreBoard extends React.Component {
	constructor(props) {
		super(props);
		this.handleTeamANameChange = this.handleTeamANameChange.bind(this);
		this.handleTeamBNameChange = this.handleTeamBNameChange.bind(this);
		this.handlePlayerAChange = this.handlePlayerAChange.bind(this);
		this.handlePlayerBChange = this.handlePlayerBChange.bind(this);
		this.handlePeriodChange = this.handlePeriodChange.bind(this);
		this.handleMatchClockChange = this.handleMatchClockChange.bind(this);
		this.handleSelectedAPlayerChange = this.handleSelectedAPlayerChange.bind(this);
		this.handleSelectedBPlayerChange = this.handleSelectedBPlayerChange.bind(this);
		this.handleSelectedActionChange = this.handleSelectedActionChange.bind(this);
		this.handleSelectedSubstAPlayerChange = this.handleSelectedSubstAPlayerChange.bind(this);
		this.handleSelectedSubstBPlayerChange = this.handleSelectedSubstBPlayerChange.bind(this);
		this.handleSelectedActionChange = this.handleSelectedActionChange.bind(this);
		this.handleValidateAction = this.handleValidateAction.bind(this);
		this.findPlayerAWithNum = this.findPlayerAWithNum.bind(this);
		this.findPlayerBWithNum = this.findPlayerBWithNum.bind(this);
		this.sendEventToApi = this.sendEventToApi.bind(this);
		this.getEventsFromApi = this.getEventsFromApi.bind(this);
		this.updateEventInApi = this.updateEventInApi.bind(this);
		this.deleteEventFromApi = this.deleteEventFromApi.bind(this);
		this.createMatchWithApi = this.createMatchWithApi.bind(this);
		this.clearMatchWithApi = this.clearMatchWithApi.bind(this);
		this.loadRandomData = this.loadRandomData.bind(this);
		let teamAStartPlayers = [];
		let teamBStartPlayers = [];
		let teamASubstitutePlayers = [];
		let teamBSubstitutePlayers = [];
		for (let i = 0; i < this.props.nbStartPlayers; i++) {
			teamAStartPlayers[i] = {num: i+1, name: ""};
		}
		for (let i = 0; i < this.props.nbStartPlayers; i++) {
			teamBStartPlayers[i] = {num: i+1, name: ""};
		}
		for (let i = 0; i < this.props.nbSubstPlayers; i++) {
			teamASubstitutePlayers[i] = {num: i+this.props.nbStartPlayers+1, name: ""};
		}
		for (let i = 0; i < this.props.nbSubstPlayers; i++) {
			teamBSubstitutePlayers[i] = {num: i+this.props.nbStartPlayers+1, name: ""};
		}
		this.state = {
			teamAName: "",
			teamAStartPlayers: teamAStartPlayers,
			teamASubstitutePlayers: teamASubstitutePlayers,
			teamBName: "",
			teamBStartPlayers: teamBStartPlayers,
			teamBSubstitutePlayers: teamBSubstitutePlayers,
			period: "",
			matchClockTime: 0,
			selectedAPlayer: 0,
			selectedBPlayer: 0,
			selectedSubstAPlayer: 0,
			selectedSubstBPlayer: 0,
			selectedFirst: "",
			selectedAction: "",
			events: [],
			selectedEvent: 0,
		}
	}

	handleTeamANameChange(name) {
		if (this.state.matchClockTime === 0) {
			this.setState(state => ({teamAName: name}));
		}
		else {
			alert("Le nom de l'équipe ne peut pas être modifié après le début du match");
		}
	}

	handleTeamBNameChange(name) {
		if (this.state.matchClockTime === 0) {
			this.setState(state => ({teamBName: name}));
		}
		else {
			alert("Le nom de l'équipe ne peut pas être modifié après le début du match");
		}
	}

	handlePlayerAChange(index, num, name, type) {
		let players;
		if (type === "starting") {
			players = this.state.teamAStartPlayers;
			players[index] = {num: num, name: name}
			this.setState(state => ({teamAStartPlayers: players}));
		}
		else if (type === "substitute") {
			players = this.state.teamASubstitutePlayers;
			players[index] = {num: num, name: name}
			this.setState(state => ({teamASubstitutePlayers: players}));
		}
	}

	handlePlayerBChange(index, num, name, type) {
		let players;
		if (type === "starting") {
			players = this.state.teamBStartPlayers;
			players[index] = {num: num, name: name}
			this.setState(state => ({teamBStartPlayers: players}));
		}
		else if (type === "substitute") {
			players = this.state.teamBSubstitutePlayers;
			players[index] = {num: num, name: name}
			this.setState(state => ({teamBSubstitutePlayers: players}));
		}
	}

	handleSelectedAPlayerChange(value) {
		this.setState(state => ({selectedAPlayer: value}));
		if (this.state.selectedFirst === "") {
			this.setState(state => ({selectedFirst: "A"}));
		}
	}

	handleSelectedBPlayerChange(value) {
		this.setState(state => ({selectedBPlayer: value}));
		if (this.state.selectedFirst === "") {
			this.setState(state => ({selectedFirst: "B"}));
		}
	}

	handleSelectedActionChange(value) {
		this.setState(state => ({selectedAction: value}));
	}

	handleSelectedSubstAPlayerChange(value) {
		this.setState(state => ({selectedSubstAPlayer: value}));
	}

	handleSelectedSubstBPlayerChange(value) {
		this.setState(state => ({selectedSubstBPlayer: value}));
	}

	handlePeriodChange(value) {
		this.setState(state => ({period: value}));
	}

	handleMatchClockChange(value) {
		this.setState(state => ({matchClockTime: value}));
	}

	findPlayerAWithNum(num) {
		let player = this.state.teamAStartPlayers.find(player => player.num === num);
		if (!player) {
			player = this.state.teamASubstitutePlayers.find(player => player.num === num);
		}
		if (!player) {
			return null;
		}
		return player;
	}

	findPlayerBWithNum(num) {
		let player = this.state.teamBStartPlayers.find(player => player.num === num);
		if (!player) {
			player = this.state.teamBSubstitutePlayers.find(player => player.num === num);
		}
		if (!player) {
			return null;
		}
		return player;
	}

	handleValidateAction() {
		let firstPlayer = null;
		let secondPlayer = null;
		let action_desc = "";
		// Specific case of substitution
		if (this.state.selectedAction === "remplacement") {
			if (this.state.selectedFirst === "A") {
				firstPlayer = this.findPlayerAWithNum(this.state.selectedAPlayer);
				secondPlayer = this.findPlayerAWithNum(this.state.selectedSubstAPlayer);
			} else if (this.state.selectedFirst === "B") {
				firstPlayer = this.findPlayerBWithNum(this.state.selectedBPlayer);
				secondPlayer = this.findPlayerBWithNum(this.state.selectedSubstBPlayer);
			}
			if (firstPlayer === null || secondPlayer === null) {
				return;
			}
			action_desc = this.state.selectedAction + "|" + this.state.selectedFirst + "|" + firstPlayer.num + " " + firstPlayer.name + "|" + secondPlayer.num + " " + secondPlayer.name;
			// Send to API
			this.sendEventToApi(action_desc, this.state.selectedAction);
			// Switch players locally
			if (this.state.selectedFirst === "A") {
				let startPlayers = [...this.state.teamAStartPlayers];
				let substPlayers = [...this.state.teamASubstitutePlayers];
				let startPlayerIndex = startPlayers.indexOf(firstPlayer);
				let substPlayerIndex = substPlayers.indexOf(secondPlayer);
				let startPlayer = {...startPlayers[startPlayerIndex]};
				let substPlayer = {...substPlayers[substPlayerIndex]};
				startPlayer.num = secondPlayer.num;
				startPlayer.name = secondPlayer.name;
				substPlayer.num = firstPlayer.num;
				substPlayer.name = firstPlayer.name;
				startPlayers[startPlayerIndex] = startPlayer;
				substPlayers[substPlayerIndex] = substPlayer;
				// Reset selection
				this.setState(state => ({teamAStartPlayers: startPlayers, teamASubstitutePlayers: substPlayers, selectedAPlayer: 0, selectedBPlayer: 0, selectedAction: "", selectedFirst: ""}));
			}
			else if (this.state.selectedFirst === "B") {
				let startPlayers = [...this.state.teamBStartPlayers];
				let substPlayers = [...this.state.teamBSubstitutePlayers];
				let startPlayerIndex = startPlayers.indexOf(firstPlayer);
				let substPlayerIndex = substPlayers.indexOf(secondPlayer);
				let startPlayer = {...startPlayers[startPlayerIndex]};
				let substPlayer = {...substPlayers[substPlayerIndex]};
				startPlayer.num = secondPlayer.num;
				startPlayer.name = secondPlayer.name;
				substPlayer.num = firstPlayer.num;
				substPlayer.name = firstPlayer.name;
				startPlayers[startPlayerIndex] = startPlayer;
				substPlayers[substPlayerIndex] = substPlayer;
				// Reset selection
				this.setState(state => ({teamBStartPlayers: startPlayers, teamBSubstitutePlayers: substPlayers, selectedAPlayer: 0, selectedSubstAPlayer: 0, selectedBPlayer: 0, selectedSubstBPlayer: 0, selectedAction: "", selectedFirst: ""}));
			}
			return;
		}
		if (this.state.selectedFirst === "A") {
			firstPlayer = this.findPlayerAWithNum(this.state.selectedAPlayer);
			secondPlayer = this.findPlayerBWithNum(this.state.selectedBPlayer);
		} else if (this.state.selectedFirst === "B") {
			firstPlayer = this.findPlayerBWithNum(this.state.selectedBPlayer);
			secondPlayer = this.findPlayerAWithNum(this.state.selectedAPlayer);
		}
		if (firstPlayer === null) {
			if (this.state.selectedFirst === "A" && this.state.selectedAPlayer === -1) {
				action_desc = this.state.selectedAction + "|" + this.state.selectedFirst + "|-1 " + this.state.teamAName;
			}
			else if (this.state.selectedFirst === "B" && this.state.selectedBPlayer === -1) {
				action_desc = this.state.selectedAction + "|" + this.state.selectedFirst + "|-1 " + this.state.teamBName;
			}
			else {
				action_desc = this.state.selectedAction;
			}
		}
		else if (secondPlayer === null) {
			action_desc = this.state.selectedAction + "|" + this.state.selectedFirst + "|" + firstPlayer.num + " " + firstPlayer.name;
		}
		else {
			action_desc = this.state.selectedAction + "|" + this.state.selectedFirst + "|" + firstPlayer.num + " " + firstPlayer.name + "|" + secondPlayer.num + " " + secondPlayer.name;
		}

		// Send to API
		this.sendEventToApi(action_desc, this.state.selectedAction);

		// Reset selection
		this.setState(state => ({selectedAPlayer: 0, selectedSubstAPlayer: 0, selectedBPlayer: 0, selectedSubstBPlayer: 0, selectedAction: "", selectedFirst: ""}));
	}

	loadRandomData(genre) {
		let maleNames = [
			"Sacha Niel",
			"Jean-Noël Bombelles",
			"Cédric Bourguignon",
			"Gabriel Carré",
			"Lilian Sardou",
			"Abeau Delcroix",
			"Godefroy Dembélé",
			"Charles Mignard",
			"Jean-Paul Aveline",
			"Jean-Yves Monteil",
			"Jean-Baptiste Crépin",
			"Denis Martin",
			"Blaise Brazier",
			"Léonard Fresnel",
			"Maxime Lémery",
			"Jacques Marais",
			"Gustave Bonhomme",
			"Philippe Donnet",
			"Jean-François Gallois",
			"Éloi Vernier",
			"Adam Chéron",
			"Lionel Cordonnier",
			"Gaspard Chéron",
			"Jean-Christophe Gigot",
			"Christopher Beaugendre",
			"Gérôme Boulanger",
			"Marc-Antoine Deniau",
			"Lucas Roatta",
			"Nathanaël Alarie",
			"Henri Suchet",
			"Fabrice Micheaux",
			"Pierre-Marie Corne",
			"Jacques De Guignes",
			"Gaylord Fresnel",
			"Nathan Duverger",
			"Emmanuel Hector",
			"Matthias Moineau",
			"Gilbert Boucher",
			"Christopher Ange",
			"Gérôme Spanghero",
			"Adolphe Boffrand",
			"Godefroy Lemaigre",
			"Fabien Dupont",
			"Maxime Bardin",
			"Léonard Hauet",
			"Léo Boutroux",
			"Jonathan Sylvestre",
			"Grégory Trémaux",
			"Maxence Asselineau",
			"Jean-Christophe De la Croix"
		];
		let femaleNames = [
			"Flora Ardouin",
			"Fabienne Mallet",
			"Radegonde Beauchamp",
			"Emma Brian",
			"Marielle Fétique",
			"Isabelle Valluy",
			"Amandine De Villepin",
			"Alix Michaux",
			"Bethsabée Passereau",
			"Ivanna Crépin",
			"Eugénie Bourguignon",
			"Gaëtane Flandin",
			"Adeline Deniau",
			"Élise Lazard",
			"Solenne Boucher",
			"Mathilde LeBeau",
			"Haydée Houdin",
			"Adèle Lambert",
			"Honorine Portier",
			"Nicole Plessis",
			"Isabelle Moitessier",
			"Dolorès Berlioz",
			"Sylviane Gérin",
			"Solène Michaux",
			"Francine Leblanc",
			"Marie-Christine Rouanet",
			"Stéphane Chaucer",
			"Marie-Hélène Bessette",
			"Lydie Kléber",
			"Marie-Thérèse Soyer",
			"Florence Lecocq",
			"Marie-Claude Plessis",
			"Janine Ménard",
			"Emilie Barrault",
			"Annie Pasteur",
			"Sylvie Blondeau",
			"Blandine Daucourt",
			"Carine Gainsbourg",
			"Julia Clérisseau",
			"Margaux Lacan",
			"Lou De Villiers",
			"Clara Bissonnette",
			"Solenne Mallet",
			"Camille Plantier",
			"Dominique Bouhier",
			"Lucrèce Leavitt",
			"Sylviane Gallois",
			"Marinette Carré",
			"Marie-José Millet",
			"Rose-Marie Bessette"
		];
		let teamAPlayers = [];
		let teamASubstPlayers = [];
		let teamBPlayers = [];
		let teamBSubstPlayers = [];
		for (let i = 0; i < this.props.nbStartPlayers; i++) {
			let playerAName;
			let playerBName;
			if (genre === "f") {
				playerAName = femaleNames.splice((Math.random() * femaleNames.length) | 0, 1)[0];
				playerBName = femaleNames.splice((Math.random() * femaleNames.length) | 0, 1)[0];
			} else {
				playerAName = maleNames.splice((Math.random() * maleNames.length) | 0, 1)[0];
				playerBName = maleNames.splice((Math.random() * maleNames.length) | 0, 1)[0];
			}
			teamAPlayers[i] = {num: i+1, name: playerAName};
			teamBPlayers[i] = {num: i+1, name: playerBName};
		}
		for (let i = 0; i < this.props.nbSubstPlayers; i++) {
			let playerAName;
			let playerBName;
			if (genre === "f") {
				playerAName = femaleNames.splice((Math.random() * femaleNames.length) | 0, 1)[0];
				playerBName = femaleNames.splice((Math.random() * femaleNames.length) | 0, 1)[0];
			} else {
				playerAName = maleNames.splice((Math.random() * maleNames.length) | 0, 1)[0];
				playerBName = maleNames.splice((Math.random() * maleNames.length) | 0, 1)[0];
			}
			teamASubstPlayers[i] = {num: i+this.props.nbStartPlayers+1, name: playerAName};
			teamBSubstPlayers[i] = {num: i+this.props.nbStartPlayers+1, name: playerBName};
		}
		this.setState(state => ({teamAStartPlayers: teamAPlayers, teamASubstitutePlayers: teamASubstPlayers, teamBStartPlayers: teamBPlayers, teamBSubstitutePlayers: teamBSubstPlayers}));
	}

	createMatchWithApi() {
		let sport = this.props.sport;
		let organisateur = "Champ'S";
		let codeRencontre = this.props.codeRencontre;
		let competition = "DEMO " + this.props.sport;
		let groupe = "Groupe demo";
		let date = format(new Date(), "dd/MM/yyyy hh:mm");
		let journee = "J12";
		let salle = {id: 12, adresse: "Avenue Reille Paris 14"};
		let ivsData = {arn: "arn:aws:ivs:us-east-1:311122646616:channel/wDPOf4v0KvE3", ingest_server: "rtmps://a03ddb580441.global-contribute.live-video.net:443/app/", stream_key: "sk_us-east-1_5Q4eFTMRrL76_i1ihj0if8xnXfOHzHAqC8F3P6RQvQQ", stream_url: "https://a03ddb580441.us-east-1.playback.live-video.net/api/video/v1/us-east-1.311122646616.channel.wDPOf4v0KvE3.m3u8"}
		let equipeA = {id: 1, nom: this.state.teamAName, no_capitaine: 1, nom_capitaine: this.state.teamAStartPlayers[0].name, receveur: true, joueurs: this.state.teamAStartPlayers}
		let equipeB = {id: 2, nom: this.state.teamBName, no_capitaine: 1, nom_capitaine: this.state.teamBStartPlayers[0].name, receveur: false, joueurs: this.state.teamBStartPlayers}
		let jsonData = JSON.stringify({sport: sport, organisateur: organisateur, code_rencontre: codeRencontre, competition: competition, groupe: groupe, date: date, journee: journee, salle: salle, equipe_a: equipeA, equipe_b: equipeB, ivsdata: ivsData});
		// Replace num with numero
		jsonData = jsonData.split('"num":').join('"numero":');
		// Replace name with nom
		jsonData = jsonData.split('"name":').join('"nom":');
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		fetch("https://dev00.champs.fr/alpha/matches", requestOptions)
			.then(response => response.json())
			.then((data) => console.log('This is your data', data));
	}

	clearMatchWithApi() {
		let codeRencontre = this.props.codeRencontre;
		const requestOptions = {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
		};
		fetch("https://dev00.champs.fr/alpha/matches/"+codeRencontre, requestOptions)
			.then(response => response.json())
			.then((data) => console.log('This is your data', data));
	}

	sendEventToApi(action_desc, action_type) {
		let codeRencontre = this.props.codeRencontre;
		let timeStamp = this.state.matchClockTime * 1000;
		let periode = this.state.period
		let sport = this.props.sport
		let arn = "arn:aws:ivs:us-east-1:311122646616:channel/wDPOf4v0KvE3"
		let jsonData = JSON.stringify({action_desc: action_desc, action_type: action_type, code_rencontre: codeRencontre, periode: periode, timestamp: timeStamp, arn: arn, sport: sport});
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		let apiUrl = "";
		if (this.props.alpha) {
			apiUrl = "https://dev00.champs.fr/alpha/events";
		}
		else {
			apiUrl = "https://dev00.champs.fr/events";
		}
		fetch(apiUrl, requestOptions)
			.then(response => response.json())
			.then((data) => console.log('This is your data', data));
	}

	getEventsFromApi() {
		let codeRencontre = this.props.codeRencontre;
		let apiUrl = "";
		if (this.props.alpha) {
			apiUrl = "https://dev00.champs.fr/alpha/events/"+codeRencontre;
		}
		else {
			apiUrl = "https://dev00.champs.fr/events/"+codeRencontre;
		}
		fetch(apiUrl)
			.then(response => response.json())
			.then((data) => this.setState(state => ({events: data})));
	}

	updateEventInApi(id, action_desc, action_type) {
		let codeRencontre = this.props.codeRencontre;
		let timeStamp = this.state.matchClockTime * 1000;
		let periode = this.state.period
		let sport = this.props.sport
		let arn = "arn:aws:ivs:us-east-1:311122646616:channel/wDPOf4v0KvE3"
		let jsonData = JSON.stringify({action_desc: action_desc, action_type: action_type, code_rencontre: codeRencontre, periode: periode, timestamp: timeStamp, arn: arn, sport: sport});
		const requestOptions = {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		let apiUrl = "";
		if (this.props.alpha) {
			apiUrl = "https://dev00.champs.fr/alpha/events/"+id;
		}
		else {
			apiUrl = "https://dev00.champs.fr/events/"+id;
		}
		fetch(apiUrl, requestOptions)
			.then(response => response.json())
			.then((data) => console.log('This is your data', data));
	}

	deleteEventFromApi(id) {
		const requestOptions = {
			method: "DELETE",
		};
		let apiUrl = "";
		if (this.props.alpha) {
			apiUrl = "https://dev00.champs.fr/alpha/events/"+id;
		}
		else {
			apiUrl = "https://dev00.champs.fr/events/"+id;
		}
		fetch(apiUrl, requestOptions)
			.then(response => response.json())
			.then((data) => console.log('This is your data', data));
	}

	render() {
		return (
			<>
			<Grid2 container spacing={2} alignItems="center" justifyContent="center">
				<Grid2 xs={12}>
					{this.props.sport}
				</Grid2>
				<Grid2 xs="3">
					<Button variant="contained" onClick={this.createMatchWithApi}>Créer match</Button>
				</Grid2>
				<Grid2 xs="3">
					<Button variant="contained" onClick={this.clearMatchWithApi}>Purger match</Button>
				</Grid2>
				<Grid2 xs="3">
					<Button variant="contained" onClick={() => this.loadRandomData('m')}>Créer équipes masculines</Button>
				</Grid2>
				<Grid2 xs="3">
					<Button variant="contained" onClick={() => this.loadRandomData('f')}>Créer équipes féminines</Button>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2} alignItems="center" justifyContent="center">
				<Grid2 xs={6}>
					<Team name={this.state.teamAName} startPlayers={this.state.teamAStartPlayers} substitutePlayers={this.state.teamASubstitutePlayers} nbSubstPlayers={this.props.nbSubstPlayers} onTeamNameChange={this.handleTeamANameChange} onPlayerChange={this.handlePlayerAChange} onPlayerSelect={this.handleSelectedAPlayerChange} onSubstPlayerSelect={this.handleSelectedSubstAPlayerChange} selectedPlayer={this.state.selectedAPlayer} selectedSubstPlayer={this.state.selectedSubstAPlayer}/>
				</Grid2>
				<Grid2 xs={6}>
					<Team name={this.state.teamBName} startPlayers={this.state.teamBStartPlayers} substitutePlayers={this.state.teamBSubstitutePlayers} nbSubstPlayers={this.props.nbSubstPlayers} onTeamNameChange={this.handleTeamBNameChange} onPlayerChange={this.handlePlayerBChange} onPlayerSelect={this.handleSelectedBPlayerChange} onSubstPlayerSelect={this.handleSelectedSubstBPlayerChange} selectedPlayer={this.state.selectedBPlayer} selectedSubstPlayer={this.state.selectedSubstBPlayer}/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2} alignItems="center" justifyContent="center">
				<Clock value={this.state.matchClockTime} handleClockChange={this.handleMatchClockChange}/>
				<Period period={this.state.period} handlePeriodChange={this.handlePeriodChange} periods={this.props.periods}/>
				<Grid2 xs={4}>
					<Grid2 container columns={this.props.nbStartPlayers} spacing={2} alignItems="center" justifyContent="center">
						<Team render_short="true" name={this.state.teamAName} startPlayers={this.state.teamAStartPlayers} substitutePlayers={this.state.teamASubstitutePlayers} onTeamNameChange={this.handleTeamANameChange} onPlayerChange={this.handlePlayerAChange} onPlayerSelect={this.handleSelectedAPlayerChange} onSubstPlayerSelect={this.handleSelectedSubstAPlayerChange} selectedPlayer={this.state.selectedAPlayer} selectedSubstPlayer={this.state.selectedSubstAPlayer}/>
					</Grid2>
				</Grid2>
				<Grid2 xs={4}>
						<Grid2 container spacing={2}>
						{this.props.actions.map((action, index) => {
							return <Grid2 xs="auto"><Action name={action} onActionSelect={this.handleSelectedActionChange}/></Grid2>
						})}
						{(() => {
							if (!this.props.alpha) {
								return <><Grid2 xs="auto"><Action name="pub_flux" onActionSelect={this.handleSelectedActionChange}/></Grid2><Grid2 xs="auto"><Action name="fin_pub_flux" onActionSelect={this.handleSelectedActionChange}/></Grid2><Grid2 xs="auto"><Action name="pub_stat" onActionSelect={this.handleSelectedActionChange}/></Grid2><Grid2 xs="auto"><Action name="fin_pub_stat" onActionSelect={this.handleSelectedActionChange}/></Grid2></>
							}
						})()}
						{(() => {
							if (this.props.nbSubstPlayers > 0) {
								return <Grid2 xs="auto"><Action name="remplacement" onActionSelect={this.handleSelectedActionChange}/></Grid2>
							}
						})()}
						<Grid2 xs={12}>
							<Button variant="outlined" onClick={this.handleValidateAction}>Validate</Button>
						</Grid2>
					</Grid2>
				</Grid2>
				<Grid2 xs={4} /*xsOffset="auto"*/>
					<Grid2 container columns={this.props.nbStartPlayers} spacing={2} alignItems="center" justifyContent="center">
						<Team render_short="true" name={this.state.teamBName} startPlayers={this.state.teamBStartPlayers} substitutePlayers={this.state.teamBSubstitutePlayers} onTeamNameChange={this.handleTeamBNameChange} onPlayerChange={this.handlePlayerBChange} onPlayerSelect={this.handleSelectedBPlayerChange} onSubstPlayerSelect={this.handleSelectedSubstBPlayerChange} selectedPlayer={this.state.selectedBPlayer} selectedSubstPlayer={this.state.selectedSubstBPlayer}/>
					</Grid2>
				</Grid2>
			</Grid2>
			<Button variant="contained" onClick={() => this.getEventsFromApi()}>Test API</Button>
			<Grid2 container spacing={2} alignItems="left" justifyContent="left">
			{this.state.events.map((ev, index) => {
				return (
					<>
					<Grid2 xs={2}>{ev.timestamp}</Grid2>
					<Grid2 xs={8}>{ev.action_desc}</Grid2>
					<Grid2 xs={1}><EditIcon onClick={() => alert(ev.elastic_id)}/></Grid2>
					<Grid2 xs={1}><DeleteIcon onClick={() => this.deleteEventFromApi(ev.elastic_id)}/></Grid2>
					</>
				)
			})}
			</Grid2>
			</>
		);
	}
}

class Team extends React.Component {
	constructor(props) {
		super(props);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handlePlayerChange = this.handlePlayerChange.bind(this);
		this.handlePlayerSelect = this.handlePlayerSelect.bind(this);
		this.handleSubstPlayerSelect = this.handleSubstPlayerSelect.bind(this);
		this.state = {
			selectedPlayerNum: 0
		}
	}

	handleNameChange(e) {
		this.props.onTeamNameChange(e.target.value);
	}

	handlePlayerChange(index, num, name, type) {
		this.props.onPlayerChange(index, num, name, type);
	}

	handlePlayerSelect(num) {
		this.props.onPlayerSelect(num);
	}

	handleSubstPlayerSelect(num) {
		this.props.onSubstPlayerSelect(num);
	}

	render () {
		let teamRender;
		if (this.props.selectedPlayer === -1) {
			teamRender = <><Grid2 xs={1}><Player render_short="true" type="team" num={-1} name={this.props.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect} selected="true"/></Grid2><Grid2 xs={7}>{this.props.name}</Grid2></>;
		}
		else {
			teamRender = <><Grid2 xs={1}><Player render_short="true" type="team" num={-1} name={this.props.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect}/></Grid2><Grid2 xs={7}>{this.props.name}</Grid2></>;
		}
		if (this.props.render_short) {
			/* FIXME : add a "team" fake player when player that performed the action is not identified */
			return (
				<>
				{teamRender}
				{this.props.startPlayers.map((player, index) => {
					if (this.props.selectedPlayer === player.num) {
						return <Grid2 xs={4}><Player render_short="true" index={index} type="starting" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect} selected="true"/></Grid2>
					}
					else {
						return <Grid2 xs={4}><Player render_short="true" index={index} type="starting" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect}/></Grid2>
					}
				})}
				{this.props.substitutePlayers.map((player, index) => {
					if (this.props.selectedSubstPlayer === player.num) {
						return <Grid2 xs={4}><Player render_short="true" index={index} type="substitute" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handleSubstPlayerSelect} selected="true"/></Grid2>
					}
					else {
						return <Grid2 xs={4}><Player render_short="true" index={index} type="substitute" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handleSubstPlayerSelect}/></Grid2>
					}
				})}
				{this.props.startPlayers.map((player, index) => {
					if (this.props.selectedPlayer === player.num) {
						return <Grid2 xs={6}>{player.name}</Grid2>
					}
					return ""
				})}
				{this.props.substitutePlayers.map((player, index) => {
					if (this.props.selectedSubstPlayer === player.num) {
						return <Grid2 xs={6}>{player.name}</Grid2>
					}
					return ""
				})}
				</>
			);
		}
		else {
			let remplacants;
			if (this.props.nbSubstPlayers > 0) {
				remplacants = <thead><tr><th className="team_player_type">Remplaçants</th></tr></thead>;
			}
			else {
				remplacants = "";
			}
			return (
				<div className="team">
				<table className="team">
				<thead>
				<tr>
				<th className="team_name">
				<TextField label="Nom équipe" value={this.props.name} onChange={this.handleNameChange}/>
				</th>
				</tr>
				<tr>
				<th className="team_player_type">
				Titulaires
				</th>
				</tr>
				</thead>
				<tbody>
				{this.props.startPlayers.map((player, index) => {
					if (this.props.selectedPlayer === player.num) {
						return <tr><Player index={index} type="starting" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect} selected="true"/></tr>
					}
					else {
						return <tr><Player index={index} type="starting" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handlePlayerSelect}/></tr>
					}
				})}
				</tbody>
				{remplacants}
				<tbody>
				{this.props.substitutePlayers.map((player, index) => {
					if (this.props.selectedSubstPlayer === player.num) {
						return <tr><Player index={index} type="substitute" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handleSubstPlayerSelect} selected="true"/></tr>
					}
					else {
						return <tr><Player index={index} type="substitute" num={player.num} name={player.name} onPlayerChange={this.handlePlayerChange} onPlayerSelect={this.handleSubstPlayerSelect}/></tr>
					}
				})}
				</tbody>
				</table>
				</div>
			);
		};
	}
}

class Player extends React.Component {
	constructor(props) {
		super(props);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleNumChange = this.handleNumChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleNameChange(e) {
		this.props.onPlayerChange(this.props.index, this.props.num, e.target.value, this.props.type);
	}

	handleNumChange(e) {
		this.props.onPlayerChange(this.props.index, e.target.value, this.props.name, this.props.type);
	}

	handleSelect() {
		this.props.onPlayerSelect(this.props.num);
	}

	render() {
		const name = this.props.name;
		const num = this.props.num;
		if (this.props.render_short) {
			if (this.props.type === "team") {
				if (this.props.selected) {
					return (<Avatar sx={{ bgcolor: "#64DCBE" }} onClick={this.handleSelect}></Avatar>);
				}
				else {
					return (<Avatar sx={{ bgcolor: "#FFFFFF" }} onClick={this.handleSelect}></Avatar>);
				}
			}
			else if (this.props.selected) {
				return (<Avatar sx={{ bgcolor: "#64DCBE" }} onClick={this.handleSelect}>{num}</Avatar>);
			}
			else if (this.props.type === "starting") {
				return (<Avatar sx={{ bgcolor: "#FFFFFF" }} onClick={this.handleSelect}>{num}</Avatar>);
			}
			else {
				return (<Avatar sx={{ bgcolor: grey[500] }} onClick={this.handleSelect}>{num}</Avatar>);
			}
		}
		else {
			if (this.props.selected) {
				return (
					<td className="player" onClick={this.handleSelect}>
					<TextField variant="standard" label="Numéro" className={this.props.playerNumClass} value={num} onChange={this.handleNumChange}/>
					<TextField variant="standard" label="Nom du joueur" className={this.props.playerNameClass} value={name} onChange={this.handleNameChange}/>
					<CheckIcon />
					</td>
				);
			} else {
				return (
					<td className="player" onClick={this.handleSelect}>
					<TextField variant="standard" label="Numéro" className={this.props.playerNumClass} value={num} onChange={this.handleNumChange}/>
					<TextField variant="standard" label="Nom du joueur"  className={this.props.playerNameClass} value={name} onChange={this.handleNameChange}/>
					</td>
				);
			}
		}
	}
}


class Action extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleSelect() {
		this.props.onActionSelect(this.props.name);
	}

	render() {
		return (
			<div className="action">
			<Button variant="contained" size="small" onClick={this.handleSelect}>{this.props.name}</Button>
			</div>
		);
	}
}

class Period extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(value) {
		this.props.handlePeriodChange(value);
	}

	render() {
		//for (let i=0; i < this.props.periods
		return (
			<>
			<Grid2 xs={12}>
				{this.props.period}
			</Grid2>
			<Grid2 xs={12}>
				{this.props.periods.map((period, index) => {
					return <Button variant="contained" size="small" onClick={() => this.handleClick(period)}>{period}</Button>
				})}
			</Grid2>
			</>
		);
	}
}

class Clock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {running: false};
		this.handleClick = this.handleClick.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.tick = this.tick.bind(this);
	}

	handleClick() {
		if (!this.state.running) {
			this.timerID = setInterval(() => this.tick(), 1000);
			this.setState(state => ({
				running: true
			}));
		}
		else {
			clearInterval(this.timerID);
			this.setState(state => ({
				running: false
			}));
		}
	}

	handleReset() {
		this.props.handleClockChange(0);
		this.setState(state => ({
			running: false
		}));
		clearInterval(this.timerID);
	}

	tick() {
		this.props.handleClockChange(this.props.value + 1);
	}

	render() {
		let buttons;
		buttons = (
			<>
			<Grid2 xs={4} xsOffset="auto">
				<Button variant="contained" size="small" onClick={this.handleClick}>
				Start/Stop
				</Button>
			</Grid2>
			<Grid2 xs={4} xsOffset="auto">
				<Button variant="contained" size="small" onClick={this.handleReset}>
				Reset
				</Button>
			</Grid2>
			</>
		);
		return (
			<>
			<Grid2 xs={2}>
				<Avatar variant="square">{("0" + Math.floor((this.props.value / 60) % 60)).slice(-2)}</Avatar>
			</Grid2>
			<Grid2 xs={2}>
				<Avatar variant="square">{("0" + (this.props.value % 60)).slice(-2)}</Avatar>
			</Grid2>
			{buttons}
			</>
		);
	}
}
